// Import Font Above Variables
$fonts_path: '../fonts/';

/* BEGIN Light */
@font-face {
  font-family: 'Open Sans';
  src: url($fonts_path + 'Light/OpenSans-Light.eot');
  src: url($fonts_path + 'Light/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
        url($fonts_path + 'Light/OpenSans-Light.woff') format('woff'),
        url($fonts_path + 'Light/OpenSans-Light.ttf') format('truetype'),
        url($fonts_path + 'Light/OpenSans-Light.svg#OpenSansBold') format('svg');
  font-weight: 300;
  font-style: normal;
}
/* END Light */
/* BEGIN Light Italic */
@font-face {
  font-family: 'Open Sans';
  src: url($fonts_path + 'LightItalic/OpenSans-LightItalic.eot');
  src: url($fonts_path + 'LightItalic/OpenSans-LightItalic.eot?#iefix') format('embedded-opentype'),
        url($fonts_path + 'LightItalic/OpenSans-LightItalic.woff') format('woff'),
        url($fonts_path + 'LightItalic/OpenSans-LightItalic.ttf') format('truetype'),
        url($fonts_path + 'LightItalic/OpenSans-LightItalic.svg#OpenSansBold') format('svg');
  font-weight: 300;
  font-style: italic;
}
/* END Light Italic */
/* BEGIN Regular */
@font-face {
  font-family: 'Open Sans';
  src: url($fonts_path + 'Regular/OpenSans-Regular.eot');
  src: url($fonts_path + 'Regular/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
        url($fonts_path + 'Regular/OpenSans-Regular.woff') format('woff'),
        url($fonts_path + 'Regular/OpenSans-Regular.ttf') format('truetype'),
        url($fonts_path + 'Regular/OpenSans-Regular.svg#OpenSansBold') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* END Regular */
/* BEGIN Italic */
@font-face {
  font-family: 'Open Sans';
  src: url($fonts_path + 'Italic/OpenSans-Italic.eot');
  src: url($fonts_path + 'Italic/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
        url($fonts_path + 'Italic/OpenSans-Italic.woff') format('woff'),
        url($fonts_path + 'Italic/OpenSans-Italic.ttf') format('truetype'),
        url($fonts_path + 'Italic/OpenSans-Italic.svg#OpenSansBold') format('svg');
  font-weight: normal;
  font-style: italic;
}
/* END Italic */
/* BEGIN Semibold */
@font-face {
  font-family: 'Open Sans';
  src: url($fonts_path + 'Semibold/OpenSans-Semibold.eot');
  src: url($fonts_path + 'Semibold/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'),
        url($fonts_path + 'Semibold/OpenSans-Semibold.woff') format('woff'),
        url($fonts_path + 'Semibold/OpenSans-Semibold.ttf') format('truetype'),
        url($fonts_path + 'Semibold/OpenSans-Semibold.svg#OpenSansBold') format('svg');
  font-weight: 600;
  font-style: normal;
}
/* END Semibold */
/* BEGIN Semibold Italic */
@font-face {
  font-family: 'Open Sans';
  src: url($fonts_path + 'SemiboldItalic/OpenSans-SemiboldItalic.eot');
  src: url($fonts_path + 'SemiboldItalic/OpenSans-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
        url($fonts_path + 'SemiboldItalic/OpenSans-SemiboldItalic.woff') format('woff'),
        url($fonts_path + 'SemiboldItalic/OpenSans-SemiboldItalic.ttf') format('truetype'),
        url($fonts_path + 'SemiboldItalic/OpenSans-SemiboldItalic.svg#OpenSansBold') format('svg');
  font-weight: 600;
  font-style: italic;
}
/* END Semibold Italic */
/* BEGIN Bold */
@font-face {
  font-family: 'Open Sans';
  src: url($fonts_path + 'Bold/OpenSans-Bold.eot');
  src: url($fonts_path + 'Bold/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
        url($fonts_path + 'Bold/OpenSans-Bold.woff') format('woff'),
        url($fonts_path + 'Bold/OpenSans-Bold.ttf') format('truetype'),
        url($fonts_path + 'Bold/OpenSans-Bold.svg#OpenSansBold') format('svg');
  font-weight: bold;
  font-style: normal;
}
/* END Bold */
/* BEGIN Bold Italic */
@font-face {
  font-family: 'Open Sans';
  src: url($fonts_path + 'BoldItalic/OpenSans-BoldItalic.eot');
  src: url($fonts_path + 'BoldItalic/OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url($fonts_path + 'BoldItalic/OpenSans-BoldItalic.woff') format('woff'),
        url($fonts_path + 'BoldItalic/OpenSans-BoldItalic.ttf') format('truetype'),
        url($fonts_path + 'BoldItalic/OpenSans-BoldItalic.svg#OpenSansBold') format('svg');
  font-weight: bold;
  font-style: italic;
}
/* END Bold Italic */
/* BEGIN Extrabold */
@font-face {
  font-family: 'Open Sans';
  src: url($fonts_path + 'OpenSans-ExtraBold.eot');
  src: url($fonts_path + 'OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url($fonts_path + 'OpenSans-ExtraBold.woff') format('woff'),
        url($fonts_path + 'OpenSans-ExtraBold.ttf') format('truetype'),
        url($fonts_path + 'OpenSans-ExtraBold.svg#OpenSansBold') format('svg');
  font-weight: 800;
  font-style: normal;
}
/* END Extrabold */
/* BEGIN Extrabold Italic */
@font-face {
  font-family: 'Open Sans';
  src: url($fonts_path + 'OpenSans-ExtraBoldItalic.eot');
  src: url($fonts_path + 'OpenSans-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url($fonts_path + 'OpenSans-ExtraBoldItalic.woff') format('woff'),
        url($fonts_path + 'OpenSans-ExtraBoldItalic.ttf') format('truetype'),
        url($fonts_path + 'OpenSans-ExtraBoldItalic.svg#OpenSansBold') format('svg');
  font-weight: 800;
  font-style: italic;
}
/* END Extrabold Italic */
