/*https://ux.mailchimp.com/patterns/helpers*/
$box_model_levels: 8;
$box_model_increment_of: 10;
$box_model_types: 'padding', 'margin';

/*
 * Compiles the padding/margins for
 * top, right, bottom, left and default
*/

.\!margin--lv0 {
  margin: 0 !important;
}

@for $i from 0 through $box_model_levels {

  @each $model in $box_model_types {
    .#{$model} {

      &--lv#{$i} {
        #{$model}: ($box_model_increment_of * $i) + px;

        &#{\@vp-xs} {
          @media screen and (max-width: $vp_xs) {
            #{$model}: ($box_model_increment_of * $i) + px;
          }
        }

        &#{\@vp-sm} {
          @media screen and (min-width: $vp_sm) and (max-width: $vp_md_max) {
            #{$model}: ($box_model_increment_of * $i) + px;
          }
        }

        &#{\@vp-md} {
          @media screen and (min-width: $vp_md) and (max-width: $vp_lg_max) {
            #{$model}: ($box_model_increment_of * $i) + px;
          }
        }

        &#{\@vp-lg} {
          @media screen and (min-width: $vp_lg) and (max-width: $vp_hd_max) {
            #{$model}: ($box_model_increment_of * $i) + px;
          }
        }

        &#{\@vp-hd} {
          @media screen and (min-width: $vp_hd) and (max-width: $vp_hd_max) {
            #{$model}: ($box_model_increment_of * $i) + px;
          }
        }

        &#{\@vp-4k} {
          @media screen and (min-width: $vp_4k) {
            #{$model}: ($box_model_increment_of * $i) + px;
          }
        }
      }

      &-top {
        &--lv#{$i} {
          #{$model}-top: ($box_model_increment_of * $i) + px;

          &#{\@vp-xs} {
            @media screen and (max-width: $vp_xs) {
              #{$model}-top: ($box_model_increment_of * $i) + px;
            }
          }

          &#{\@vp-sm} {
            @media screen and (min-width: $vp_sm) and (max-width: $vp_md_max) {
              #{$model}-top: ($box_model_increment_of * $i) + px;
            }
          }

          &#{\@vp-md} {
            @media screen and (min-width: $vp_md) and (max-width: $vp_lg_max) {
              #{$model}-top: ($box_model_increment_of * $i) + px;
            }
          }

          &#{\@vp-lg} {
            @media screen and (min-width: $vp_lg) and (max-width: $vp_hd_max) {
              #{$model}-top: ($box_model_increment_of * $i) + px;
            }
          }

          &#{\@vp-hd} {
            @media screen and (min-width: $vp_hd) and (max-width: $vp_hd_max) {
              #{$model}-top: ($box_model_increment_of * $i) + px;
            }
          }

          &#{\@vp-4k} {
            @media screen and (min-width: $vp_4k) {
              #{$model}-top: ($box_model_increment_of * $i) + px;
            }
          }
        }
      }

      &-right {
        &--lv#{$i} {
          #{$model}-right: ($box_model_increment_of * $i) + px;

          &#{\@vp-xs} {
            @media screen and (max-width: $vp_xs) {
              #{$model}-right: ($box_model_increment_of * $i) + px;
            }
          }

          &#{\@vp-sm} {
            @media screen and (min-width: $vp_sm) and (max-width: $vp_md_max) {
              #{$model}-right: ($box_model_increment_of * $i) + px;
            }
          }

          &#{\@vp-md} {
            @media screen and (min-width: $vp_md) and (max-width: $vp_lg_max) {
              #{$model}-right: ($box_model_increment_of * $i) + px;
            }
          }

          &#{\@vp-lg} {
            @media screen and (min-width: $vp_lg) and (max-width: $vp_hd_max) {
              #{$model}-right: ($box_model_increment_of * $i) + px;
            }
          }

          &#{\@vp-hd} {
            @media screen and (min-width: $vp_hd) and (max-width: $vp_hd_max) {
              #{$model}-right: ($box_model_increment_of * $i) + px;
            }
          }

          &#{\@vp-4k} {
            @media screen and (min-width: $vp_4k) {
              #{$model}-right: ($box_model_increment_of * $i) + px;
            }
          }
        }
      }

      &-bottom {

        &--lv#{$i} {
          #{$model}-bottom: ($box_model_increment_of * $i) + px;

          &#{\@vp-xs} {
            @media screen and (max-width: $vp_xs) {
              #{$model}-bottom: ($box_model_increment_of * $i) + px;
            }
          }

          &#{\@vp-sm} {
            @media screen and (min-width: $vp_sm) and (max-width: $vp_md_max) {
              #{$model}-bottom: ($box_model_increment_of * $i) + px;
            }
          }

          &#{\@vp-md} {
            @media screen and (min-width: $vp_md) and (max-width: $vp_lg_max) {
              #{$model}-bottom: ($box_model_increment_of * $i) + px;
            }
          }

          &#{\@vp-lg} {
            @media screen and (min-width: $vp_lg) and (max-width: $vp_hd_max) {
              #{$model}-bottom: ($box_model_increment_of * $i) + px;
            }
          }

          &#{\@vp-hd} {
            @media screen and (min-width: $vp_hd) and (max-width: $vp_hd_max) {
              #{$model}-bottom: ($box_model_increment_of * $i) + px;
            }
          }

          &#{\@vp-4k} {
            @media screen and (min-width: $vp_4k) {
              #{$model}-bottom: ($box_model_increment_of * $i) + px;
            }
          }
        }
      }

      &-left {
        &--lv#{$i} {
          #{$model}-left: ($box_model_increment_of * $i) + px;

          &#{\@vp-xs} {
            @media screen and (max-width: $vp_xs) {
              #{$model}-left: ($box_model_increment_of * $i) + px;
            }
          }

          &#{\@vp-sm} {
            @media screen and (min-width: $vp_sm) and (max-width: $vp_md_max) {
              #{$model}-left: ($box_model_increment_of * $i) + px;
            }
          }

          &#{\@vp-md} {
            @media screen and (min-width: $vp_md) and (max-width: $vp_lg_max) {
              #{$model}-left: ($box_model_increment_of * $i) + px;
            }
          }

          &#{\@vp-lg} {
            @media screen and (min-width: $vp_lg) and (max-width: $vp_hd_max) {
              #{$model}-left: ($box_model_increment_of * $i) + px;
            }
          }

          &#{\@vp-hd} {
            @media screen and (min-width: $vp_hd) and (max-width: $vp_hd_max) {
              #{$model}-left: ($box_model_increment_of * $i) + px;
            }
          }

          &#{\@vp-4k} {
            @media screen and (min-width: $vp_4k) {
              #{$model}-left: ($box_model_increment_of * $i) + px;
            }
          }
        }
      }
    }
  }
}

/*
 * Compiles the !padding/!margins
 * using the !important rule
 * just in case overriding is
 * close to impossible
*/

@for $i from 0 through $box_model_levels {

  @each $model in $box_model_types {
    .\!#{$model} {

      &--lv#{$i} {
        #{$model}: ($box_model_increment_of * $i) + px !important;
      }

      &-top {
        &--lv#{$i} {
          #{$model}-top: ($box_model_increment_of * $i) + px !important;
        }
      }

      &-right {
        &--lv#{$i} {
          #{$model}-right: ($box_model_increment_of * $i) + px !important;
        }
      }

      &-bottom {
        &--lv#{$i} {
          #{$model}-bottom: ($box_model_increment_of * $i) + px !important;
        }
      }

      &-left {
        &--lv#{$i} {
          #{$model}-left: ($box_model_increment_of * $i) + px  !important;
        }
      }
    }
  }
}
