.display {

  &--block {
    display: block;
  }

  &--table {
    display: table;
    width: 100%;
    height: 100%;
  }

  &--table-row {
    display: table-row;
  }

  &--table-cell {
    display: table-cell;
    vertical-align: middle;
  }
}