// Import Font Above Variables
$roboto_path: '../fonts/roboto/';

@font-face {
  font-family: 'Roboto';
  src: local(Roboto Thin),
    font-url($roboto_path + 'Roboto-Thin.woff2') format('woff2'),
    font-url($roboto_path + 'Roboto-Thin.woff') format('woff');
  font-weight: 100;
}
@font-face {
  font-family: 'Roboto';
  src: local(Roboto Light),
    font-url($roboto_path + 'Roboto-Light.woff2') format('woff2'),
    font-url($roboto_path + 'Roboto-Light.woff') format('woff');
  font-weight: 300;
}

@font-face {
  font-family: 'Roboto';
  src: local(Roboto Regular),
    font-url($roboto_path + 'Roboto-Regular.woff2') format('woff2'),
    font-url($roboto_path + 'Roboto-Regular.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: local(Roboto Medium),
    font-url($roboto_path + 'Roboto-Medium.woff2') format('woff2'),
    font-url($roboto_path + 'Roboto-Medium.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: local(Roboto Bold),
    font-url($roboto_path + 'Roboto-Bold.woff2') format('woff2'),
    font-url($roboto_path + 'Roboto-Bold.woff') format('woff');
  font-weight: 700;
}
