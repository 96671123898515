.shadow {

  &--lv1 {
    box-shadow: $box_shadow_lv1;

    &\@onHover {
      box-shadow: none;
      transition: all .3s ease;

      &:hover {
        box-shadow: $box_shadow_lv1;
      }
    }
  }

  &--lv2 {
    box-shadow: $box_shadow_lv2;

    &\@onHover {
      box-shadow: none;
      transition: all .3s ease;

      &:hover {
        box-shadow: $box_shadow_lv2;
      }
    }
  }

  &--lv3 {
    box-shadow: $box_shadow_lv3;

    &\@onHover {
      box-shadow: none;
      transition: all .3s ease;

      &:hover {
        box-shadow: $box_shadow_lv3;
      }
    }
  }

  &--lv4 {
    box-shadow: $box_shadow_lv4;

    &\@onHover {
      box-shadow: none;
      transition: all .3s ease;

      &:hover {
        box-shadow: $box_shadow_lv4;
      }
    }
  }

  &--lv5 {
    box-shadow: $box_shadow_lv5;

    &\@onHover {
      box-shadow: none;
      transition: all .3s ease;

      &:hover {
        box-shadow: $box_shadow_lv5;
      }
    }
  }

  &--lv6 {
    box-shadow: $box_shadow_lv6;

    &\@onHover {
      box-shadow: none;
      transition: all .3s ease;

      &:hover {
        box-shadow: $box_shadow_lv6;
      }
    }
  }

  &--lv7 {
    box-shadow: $box_shadow_lv7;

    &\@onHover {
      box-shadow: none;
      transition: all .3s ease;

      &:hover {
        box-shadow: $box_shadow_lv7;
      }
    }
  }

  &--lv8 {
    box-shadow: $box_shadow_lv8;

    &\@onHover {
      box-shadow: none;
      transition: all .3s ease;

      &:hover {
        box-shadow: $box_shadow_lv8;
      }
    }
  }
}

.elevate {

  &--lv1 {
    z-index: $zindex_lv1;
  }

  &--lv2 {
    z-index: $zindex_lv2;
  }

  &--lv3 {
    z-index: $zindex_lv3;
  }

  &--lv4 {
    z-index: $zindex_lv4;
  }

  &--lv5 {
    z-index: $zindex_lv5;
  }

  &--lv6 {
    z-index: $zindex_lv6;
  }

  &--lv7 {
    z-index: $zindex_lv7;
  }

  &--lv8 {
    z-index: $zindex_lv8;
  }

  &--max {
    z-index: $zindex_max;
  }

  &--under-max {
    z-index: $zindex_under_max;
  }

  &--exceed-max {
    z-index: $zindex_exceed_max;
  }
}