.media {

  &--card {
    margin-bottom: 15px;
    margin-top: 15px;
    border: 1px solid #f1f1f1;
    border-radius: 4px;
    background-color: $gray_lv8;
    color: $white;

    .media-left {
      padding-right: 5px;
    }

    .media-body {
      padding-left: 15px;
    }

    .media-object {
      max-height: 150px;
    }

    .media-social-link {
      color: $white;
      display: inline-block;
      font-size: $font_lg;
      margin-right: 5px;
      margin-top: 10px;
    }

    .media-heading {
      color: $white;
      font-weight: $font_light;
      font-size: $font_md;
      margin-top: 15px;
      margin-bottom: 10px;
    }

    .media-link {
      color: $white;
      display: block;

      .fa {
        margin-right: 10px;
      }
    }

    p {
      font-size: $font_sm;
      margin-bottom: 10px;
      line-height: 1;
    }

  }
}