.row.bg,
.bg {

  &--primary { background-color: $color_primary; }
  &--secondary { background-color: $color_secondary; }
  &--success { background-color: $color_success; }
  &--failed { background-color: $color_failed; }
  &--warning { background-color: $color_warning; }
  &--action { background-color: $color_action; }

  &--gray-lv1 { background-color: $gray_lv1; }
  &--gray-lv2 { background-color: $gray_lv2; }
  &--gray-lv3 { background-color: $gray_lv3; }
  &--gray-lv4 { background-color: $gray_lv4; }
  &--gray-lv5 { background-color: $gray_lv5; }
  &--gray-lv6 { background-color: $gray_lv6; }
  &--gray-lv7 { background-color: $gray_lv7; }
  &--gray-lv8 { background-color: $gray_lv8; }

  @media screen and (min-width: $vp_sm) {
    &--match-cols {
      overflow: hidden;

      [class*="col-"] {
        margin-bottom: -99999px;
        padding-bottom: 99999px;
      }
    }
  }
}

.\!bg {

  &--primary { background-color: $color_primary !important; }
  &--secondary { background-color: $color_secondary !important; }
  &--success { background-color: $color_success !important; }
  &--failed { background-color: $color_failed !important; }
  &--warning { background-color: $color_warning !important; }
  &--action { background-color: $color_action !important; }

  &--gray-lv1 { background-color: $gray_lv1 !important; }
  &--gray-lv2 { background-color: $gray_lv2 !important; }
  &--gray-lv3 { background-color: $gray_lv3 !important; }
  &--gray-lv4 { background-color: $gray_lv4 !important; }
  &--gray-lv5 { background-color: $gray_lv5 !important; }
  &--gray-lv6 { background-color: $gray_lv6 !important; }
  &--gray-lv7 { background-color: $gray_lv7 !important; }
  &--gray-lv8 { background-color: $gray_lv8 !important; }
}