html {
  min-height: 100%;
  font-size: 62.5%;
  position: relative;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body {
  background-color: $site_bg_color;
  font-family: $font_family;
  font-size: $font_base;
  color: $font_color;
  line-height: $line_height;
  overflow: hidden;

  &.modal-open {
    overflow: hidden;
    height: 100vh;
  }

  &.sticky--nav {
    /* Margin bottom by footer height */
    padding-top: $navbar_height_xs + px;

    @media screen and (min-width: $vp_sm) {
      padding-top: $navbar_height_sm + px;
    }
  }
}

a {
  color: $link_color;

  &:hover {
    color: darken($link_color, 10%);
    text-decoration: none;
  }
}

p {
  margin-bottom: 20px;
}


// h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {}
h1,
h2,
h3,
h4 {
  color: $font_color;
}

h1 {
  @include responsive_text_size('large');
  font-weight: $font_light;

  small {
    font-size: .5em;
    font-style: italic;
    font-weight: inherit;
    color: lighten($font_color, 20%);
  }
}

h2 {
  @include responsive_text_size('large');
  font-weight: $font_semibold;

  small {
    font-size: .5em;
    font-weight: inherit;
    color: lighten($font_color, 20%);
  }
}

h3 {
  @include responsive_text_size('medium');

  small {
    font-size: .5em;
    font-weight: inherit;
    color: lighten($font_color, 20%);
  }
}

h4 {
  @include responsive_text_size('base');

  small {
    font-size: .5em;
    font-weight: inherit;
    color: lighten($font_color, 20%);
  }
}

h5,
h6 {
  //@include responsive_text_size('base');

  small {
    color: inherit;
  }
}

small {
  //@include responsive_text_size('xsmall');
}


hr {
  margin-top: 10px;
  margin-bottom: 10px;
}

iframe {
  width: 100%;
  border: 0;
}