.navigation {

  &--main &__menu {
    background-color: $gray_lv1;
    padding-top: 30px;
  }

  &--main &__submenu {
    background-color: $color_primary;
    box-shadow: inset 0 0 10px $rgb_black_60;
  }

  &--main &__menu .nav-pills {
    text-align: center;

    @media screen and (min-width: $vp_sm) {
      text-align: left;
    }

    li + li {
      margin-top: 0;
    }

    > li > a {
      font-weight: $font_light;
      border-radius: 0;
      color: $gray_lv5;
      padding: 15px 0;
      transition: all .3s ease;

      @media screen and (min-width: $vp_sm) {
        padding: 15px 15px 15px 20px;
      }

      .menu__icon i {
        vertical-align: middle;
        margin-right: 5px;
        transition: all .3s ease;
      }
    }

    > li > a:hover,
    > .active > a,
    > .active > a:hover,
    > .active > a:focus {
      background-color: lighten($gray_lv1, 5%);
      color: $gray_lv6;
      text-shadow: $text_shadow_lv1;

      .menu__icon {
        color: $color_secondary;
      }
    }
  }

  &--main &__submenu .submenu__title {
    padding: 10px 0;
    background-color: $rgb_black_30;
    border-bottom: 1px solid $rgb_white_10;

    p {
      color: $gray_lv3;
      font-size: $font_base;
      font-weight: 300;
      margin:0;
      text-align: center;

      i {
        vertical-align: middle;
        color: $color_secondary;
        margin-right: 5px;
      }
    }
  }

  &--main &__submenu .nav-pills {

    > li {
      width: 50%;

      @media screen and (min-width: $vp_sm) {
        width: 25%;
      }
    }

    li + li {
      margin-left: 0;
    }

    > li > a {
      font-size: $font_sm;
      color: $gray_lv3;
      padding: 20px 0;
      font-weight: $font_light;
      text-align: center;
      transition: all .3s ease;

      @media screen and (min-width: $vp_sm) {
        font-size: $font_base;
        padding: 20px 0;
      }

      @media screen and (min-width: $vp_md) {
        font-size: $font_md;
        padding: 40px 0;
      }

      .submenu__icon {
        display: block;
        color: $color_secondary;
        margin: 0 auto 10px auto;
        background-color: $rgb_white_10;
        width: 65px;
        height: 65px;
        border-radius: 100%;
        transition: all .4s ease;

        @media screen and (min-width: $vp_sm) {
          margin: 0 auto 20px auto;
        }

        i {
          font-size: $font_md;
          line-height: 65px;
        }
      }
    }

    > li > a:hover,
    > li > a:focus,
    > .active > a,
    > .active > a:hover,
    > .active > a:focus {
      background-color: transparent;
      color: $white;
      text-shadow: $text_shadow_lv4;

      .submenu__icon {
        background-color: $color_secondary;
        color: $white;
        box-shadow: $box_shadow_lv4;
        text-shadow: none;
      }
    }
  }
}
