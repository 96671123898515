$main_hero: url($images_path + 'main-hero.jpg');

.jumbotron {
  padding: 0;
  margin-bottom: 0;
  background-color: $gray_lv8;

  &--fullwidth {
    width: 100%;
  }

  &--fullheight {
    min-height: calc(100vh - #{($navbar_height_xs - 50) + px});
    position: relative;
    margin-bottom: 0;
    padding: 20px 0;
    //overflow: hidden;

    @media screen and (min-width: $vp_sm) {
      padding: 10vh 0;
      min-height: 660px;
    }
  }

  &.main {
    // @extend .container;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: contain;
    background-image: $main_hero;
    height: 502px;
  }
}