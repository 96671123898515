.navbar {

  &--ui {
    @extend .navbar-default;
    background-color: $navbar_color_default;
    border: 0;
    box-shadow: $navbar_shadow;
    min-height: $navbar_height_xs + px;
    margin-bottom: 0;
    border-radius: 0;

    @media screen and (min-width: $vp_sm) {
      min-height: $navbar_height_sm + px;
    }

    @media screen and (min-width: $vp_md) {
      min-height: $navbar_height_md + px;
    }

    @media screen and (min-width: $vp_lg) {
      min-height: $navbar_height_lg + px;
    }

    .navbar-header {

      &.block {
        display: block;
        width: 100%;
        margin-right: 0;
        margin-left: 0;
      }
    }

    > .container .navbar-brand,
    > .container-fluid .navbar-brand {
      width: $logo_width_xs + px;
      height: $navbar_height_xs + px;
      background-image: url($logo_default);
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      transition: all 1s ease;
      margin-left: 5px;

      @media screen and (min-width: $vp_sm) {
        width: $logo_width_sm + px;
        height: $navbar_height_sm + px;
      }

      &.center {
        display: block;
        margin: 0 auto;
        float: none;
      }
    }

    .navbar-nav > li > a {
      font-size: $font_sm;
      padding-top: 0;
      padding-bottom: 0;
      line-height: $navbar_height_xs + px;
      min-height: $navbar_height_xs + px;
      text-transform: uppercase;

      @media screen and (min-width: $vp_sm) {
        line-height: $navbar_height_sm + px;
        min-height: $navbar_height_sm + px;
      }

      @media screen and (min-width: $vp_md) {
        line-height: $navbar_height_md + px;
        min-height: $navbar_height_md + px;
      }

      @media screen and (min-width: $vp_lg) {
        line-height: $navbar_height_lg + px;
        min-height: $navbar_height_lg + px;
      }
    }

    .navbar-nav > .active > a,
    .navbar-nav > .active > a:hover {
      color: $color_primary;
      background-color: transparent;
    }
  }
}
