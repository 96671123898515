.card {
    margin-bottom: 25px;
    margin-top: 0;
    border-radius: $well_radius;
    background-color: transparent;
    overflow: hidden;

  &__guest {
    background-color: $gray_lv2;

    .nav-pills > li + li {
      margin-left: 0;
    }

    .nav-justified > li,
    .nav-tabs.nav-justified > li {
      display: table-cell !important;
      width: 1% !important;

      a {
        margin-bottom: 0 !important;
      }
    }

    .nav > li > a {
      background-color: $rgb_white_20;
      color: $gray_lv8;
      text-decoration: none;
      border: 0;
      border-radius: 0;
      padding: 11px 15px;

      &:hover {
        background-color: $color_primary;
        color: $white;
      }
    }
  }

  &__header {
    background-color: $gray_lv1;
    text-align: center;
  }

  &__body {

    &--sm,
    &--md,
    &--lg {
      text-align: center;
    }

    &--sm {
      height: 80px;
      padding: 5px;
    }

    &--md {
      height: 110px;
    }

    &--lg {}
  }

  &__footer {}

  &__image {
    display: inline;
    margin: 0;

    &--sm {
      max-height: 115px;
    }

    &--md {
      max-height: 155px;
    }

    &--lg {
      max-height: 155px;
    }
  }

  &__title {
    font-weight: $font_light;
    margin-top: 0;

    &--sm {
      font-size: $font_sm;
    }

    &--md {
      font-size: $font_md;
    }

    &--lg {}
  }

  &__subtitle {
    font-size: $font_base;
    margin-top: 0;
    margin-bottom: 0;
  }

  &__link--text {
    font-size: $font_sm;
  }
}