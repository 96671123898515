.navbar {

  &--article {
    background-color: transparent;
    border-top: 1px solid $gray_lv1;
    border-bottom: 1px solid $gray_lv1;
    border-radius: 0;

    .navbar-nav {
      margin: 0;
    }

    .nav > li > a {
      color: $white;
      font-size: $font_sm - .2;
      padding-left: 15px;
      padding-right: 15px;
      background-color: $color_primary;

      &.nav--cta {
        background-color: $color_secondary;
      }

      &:focus,
      &:hover {
        background-color: transparent;
        color: $color_primary;
      }
    }

    .navbar-text {
      color: $gray_lv5;
      font-size: $font_sm - .2;
      margin: 0;
      line-height: 18px;
      padding: 15px 0;
    }
  }

  &--pagination {
    background-color: transparent;
    border-top: 1px solid $gray_lv1;
    border-bottom: 1px solid $gray_lv1;

    .navbar-form {
      margin: 0;

      input[type="text"] {
        border: 0;
        height: 48px;
        min-width: 250px;
      }

      .btn {
        margin: 0;
        height: 48px;
      }

      .btn--default {
        background-color: $white;
        color: $gray_lv3;
      }
    }

    .navbar-nav {
      margin: 0;
    }

    .nav > li + li {
      border-left: 1px solid $gray_lv1;
    }

    .nav > li > a {
      color: $gray_lv5;
      font-size: $font_sm - .2;
      line-height: 18px;

      &:focus,
      &:hover {
        background-color: $color_primary;
        color: $white;
      }
    }

    .nav > li.active > a {
      background-color: $color_primary;
      color: $white;
    }

    .navbar-text {
      color: $gray_lv5;
      font-size: $font_sm - .2;
      margin: 0;
      line-height: 18px;
      padding: 15px 0;
    }
  }
}