.container {
  width: 100%;

  &.full-height {
    min-height: calc(100vh - #{($navbar_height_xs + $footer_height_xs) + px});

    @media screen and (min-width: $vp_sm) {
      min-height: calc(100vh - #{($navbar_height_sm + $footer_height_sm) + px});
    }

    @media screen and (min-width: $vp_md) {
      min-height: calc(100vh - #{($navbar_height_md + $footer_height_md) + px});
    }

    @media screen and (min-width: $vp_lg) {
      min-height: calc(100vh - #{($navbar_height_lg + $footer_height_lg) + px});
    }
  }

  &--content {
    width: 100%;
    height: calc(100vh - #{$navbar_height_xs + px});
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      width: 5px;

      &:horizontal {
        height: 5px;
      }
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: $rgb_white_10;
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $color_secondary;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:window-inactive {
      background: $color_secondary;
    }

    @media screen and (min-width: $vp_sm) {
      height: calc(100vh - #{$navbar_height_sm + px});
    }

    @media screen and (min-width: $vp_md) {
      height: calc(100vh - #{$navbar_height_md + px});
    }

    @media screen and (min-width: $vp_lg) {
      height: calc(100vh - #{$navbar_height_lg + px});
    }

  }


  @media screen and (min-width: $vp_sm) {
    width: 100%;
  }

  @media screen and (min-width: $vp_md) {
    width: 90vw;
  }

  @media screen and (min-width: $vp_lg) {
    width: 80vw;
  }

  &__guest {
    @include clearfix;
    width: 100%;
    height: 110px;

    @media screen and (min-width: $vp_sm) {
      height: 125px;
    }

    @media screen and (min-width: $vp_md) {
      height: 120px;
    }

    @media screen and (min-width: $vp_lg) {
      height: 125px;
    }

    @media screen and (min-width: $vp_hd) {
      height: 210px;
    }

    @media screen and (min-width: $vp_4k) {
      height: 350px;
    }

    .img__guest,
    .img__book {
      height: 100%;
      float: left;
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
    }

    .img__guest {
      width: 60%;
    }

    .img__book {
      width: 40%;
    }
  }
}