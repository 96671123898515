.slider {

	&__container {
		width: 100%;
		overflow-x: hidden;
	}

	&__overscroll {
		width: auto;
		height: 151px;
		overflow-x: scroll;
		overflow-y: hidden;
		white-space: nowrap;

    &::-webkit-scrollbar {
      width: 5px;

      &:horizontal {
        height: 2px;
      }
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: $rgb_white_10;
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $color_secondary;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:window-inactive {
      background: $color_secondary;
    }

		a {
	    display: inline-block;
	    padding: 0;
	    margin: 0 0 0 -5px;
		}
	}

	&__book-img {
		height: 150px;
	}
}